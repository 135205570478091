import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Page,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Country } from '../../../models/Country';
import { User } from '../../../models/User';

import HelpersLanguage from '../../../actions/helpers/language';
import HelpersPractice from '../../../actions/helpers/practice';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserDetail: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  // const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [consentAsked, setConsentAsked] = useState<string>();
  const [mainObject, setMainObject] = useState<User>({
    auth_token: '',
    email: '',
    coach_skills: []
  });

  let i_solemnly_swear_that_i_am_up_to_no_good = true;

  let is_nutri_appointment = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_appointment'});
  let is_nutri_measures_weight = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_measures_weight'});
  let is_nutri_diary = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_diary'});
  let is_nutri_mail = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_mail'});
  let is_nutri_advice = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_advice'});

  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", (currentPractice?.id || login?.practice_id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'second_name', 'last_name', 'email', 'consent_status', 'call_name', 'birth_date', 'language_id', 'gender',
            'phone_fixed', 'phone_mobile', 'street', 'number', 'zip', 'city', 'country_id', 'spouse_name', 'national_number'
          ],
          practice_id: (currentPractice?.id || login?.practice_id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(currentPractice?.id || login?.practice_id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{custom_result: {success: boolean;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setConsentAsked(data.custom_result.success ? 'asked' : 'error');
    },
  });





  
  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  // useEffect(() => {
  //   let err:any = {};
  //   if (!mainObject.first_name) err.first_name = true;
  //   if (!mainObject.last_name) err.last_name = true;
  //   if (!mainObject.email) err.email = true;
  //   if (!!emailError) err.email = true;
  //   if (!mainObject.birth_date) err.birth_date = true;

  //   setErrors(err);
  // }, [mainObject]);

  return <Page
    title={(object || {}).first_name}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      }
    ]}
  >
    <Grid container spacing={1}>

      <Grid item xs={12} md={4} xl={3}>

        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.profile.email")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              col 1
            </Grid>

          </Grid>
        </Paper>

      </Grid>

      <Grid item xs={12} md={4} xl={3}>

        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.profile.email")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              col 2
            </Grid>

          </Grid>
        </Paper>

      </Grid>

      <Grid item xs={12} md={4} xl={3}>

        <Paper sx={{padding: 2}}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{t("coach.submenu.profile.email")}</Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              col 3
            </Grid>

          </Grid>
        </Paper>

      </Grid>

    </Grid>
    
    {/* <SnackSaved open={savedOpen} setOpen={setSavedOpen} /> */}
  </Page>;
}
