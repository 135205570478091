import React, { FunctionComponent, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useAtomValue, useAtom } from 'jotai';
import { getCookie } from 'react-use-cookie';

import { fetch_one, check_subdomain } from '../lib/server_helper_new';
import { loginAtom } from '../lib/auth';
import { currentPracticeAtom } from '../lib/practice';

import { createTheme, Theme, ThemeProvider } from '@mui/material/styles';

import '../i18n.ts';
import Background from '../images/nutriportalbackground.png';

import { ClientAppointmentNew } from './client/appointment/New';
import { ClientAppointmentList } from './client/appointment/List';
import { ClientAppointmentWorkshops } from './client/appointment/Workshops';
import { ClientDashboard } from './client/Dashboard';
import { ClientDiary } from './client/Diary';
import { ClientDiaryWeek } from './client/DiaryWeek';
import { ClientEditNutriMail } from './client/mail/EditNutriMail';
import { ClientIntake } from './client/Intake';
import { ClientMeasuresWake } from './client/measure/Wake';
import { ClientMeasuresWeight } from './client/measure/Weight';
import { ClientNews } from './client/News';
import { ClientPracticeFindPractice } from './client/practice/FindPractice';
import { ClientPracticeMyPractice } from './client/practice/MyPractice';
import { ClientSettings } from './client/Settings';

import { CoachDashboard } from './coach/Dashboard';
import { CoachAgenda } from './coach/Agenda';
import { CoachExports } from './coach/Exports';
import { CoachExportEdit } from './coach/ExportEdit';
import { CoachUsers } from './coach/users/Users';
import { CoachUserDetail } from './coach/users/UserDetail';
import { CoachUserEdit } from './coach/users/UserEdit';
import { CoachWorkshops } from './coach/nutri_calendar/Workshops';
import { CoachWorkshopEdit } from './coach/nutri_calendar/WorkshopEdit';
import { CoachWorkshopSubscribers } from './coach/nutri_calendar/WorkshopSubscribers';

import { NutriMail } from './shared/NutriMail';
import { SharedLayout } from './shared/Layout';
import { SharedConfirmLoader } from './shared/ConfirmLoader';
import { SharedNewsItem } from './shared/NewsItem';
import { SharedOnboarding } from './shared/Onboarding';

import {
  Box,
  CssBaseline
} from '@mui/material';

import { Practice } from '../models/Practice';

type Props = {}

export const Main: FunctionComponent<Props> = () => {
  const login = useAtomValue(loginAtom);
  let subdomain = check_subdomain();

  const defaultTheme = createTheme({
    typography: {
      fontFamily: ['Nunito', 'sans-serif'].join(','),
      fontSize: 14,
      fontWeightLight: 100,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
  
      h1: {
        fontSize: "1.75rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h2: {
        fontSize: "1.6rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h3: {
        fontSize: "1.35rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h4: {
        fontSize: "1.2rem",
        fontFamily: 'Archivo',
        // color: 'rgb(16, 46, 86)'
      },
      h5: {
        fontSize: "1.1rem",
        fontFamily: 'Archivo',
      },
      h6: {
        // color: 'rgb(16, 46, 86)'
      },
      body1: {
        // color: 'rgb(16, 46, 86)'
      },
      body2: {
        // color: 'rgb(16, 46, 86)'
      }
    },
    palette: {
      primary: {
        light: '#abcb88',
        main: '#97BE6B',
        dark: '#69854a',
        contrastText: '#FFFFFF'
      },
      secondary: {
        main: '#be6b6b', // red
        light: '#cb8888',
        dark: '#854a4a',
        contrastText: '#FFFFFF'
      },
      tertiary: {
        main: '#DEBC5A'
      },
      warning: {
        main: '#E69E54',
        fade: 'rgba(230, 157, 84, 0.1)'
      },
      lightgray: "rgb(244,248,247)",
      transparant: "transparent",
      mode: 'light',
      background: {
        default: "#f8f8f8",
        // lighter: "#ffffff"
      },
      fancy_bg_img: `url(${Background})`,
    },
    shape: {
      borderRadius: 18
    },
    drawer_width: 240,
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ownerState, theme}) => ({
            fontFamily: 'Archivo',
            fontWeight: 400
          })
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'h1' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h2' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h3' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h4' },
            style: {
              fontFamily: 'Archivo',
            },
          },
          {
            props: { variant: 'h5' },
            style: {
              fontFamily: 'Archivo',
            },
          },
        ]
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            paddingTop: 12,
            paddingBottom: 12
          }
        }
      }
    }
  });

  const [theme, setTheme] = useState<Theme>(defaultTheme);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  
  useEffect(() => {
    if (subdomain.length > 0) {
      fetch_one<Practice>('/nl/v3/pub/define_practice', {
        object: 'practice',
        fields: ['id', 'custom_theme', 'get_item', 'settings', 'name', 'item_is_square', 'get_medium', 'group_move', 'group_eat', 'modules', 'get_description'],
        sub_fields: {
          modules: ['id', 'name']
        },
        subdomain: subdomain,
        full_url: window.location.host
      }, (ob, complete_data) => {
        setCurrentPractice(ob);
      }, (z) => {
        
      }, (!!login?.auth_token ? {auth_token: login?.auth_token, email: login?.email} : undefined));
    }
  }, [subdomain]);
  
  useEffect(() => {
    // console.log(login);
    // console.log(currentPractice);
    // console.log(`p- ${!!currentPractice} nota- ${!is_admin} --- switching theme`);
    if (!!currentPractice && subdomain !== 'admin') {
      if (!!currentPractice && !!currentPractice.custom_theme && !!currentPractice.custom_theme.primary_main && !!currentPractice.custom_theme.contrast_main && !!currentPractice.custom_theme.tertiary_main) {
        let pm = currentPractice.custom_theme.primary_main;
        if (pm === 'undefined') pm = '#97BE6B';
        let cm = currentPractice.custom_theme.contrast_main;
        if (cm === 'undefined') cm = '#FFFFFF';
        let tm = currentPractice.custom_theme.tertiary_main;
        if (tm === 'undefined') tm = '#F9D971';
        let bg = currentPractice.custom_theme.onboarding_bg;
        if (bg === 'undefined' || typeof(bg) === "undefined" || bg.indexOf('missing') < 0) bg = Background;
        let bg_theme = `url(${bg})`;

        setTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              ...theme.palette.primary,
              main: pm,
              contrastText: cm
            },
            tertiary: {
              ...theme.palette.tertiary,
              main: tm
            },
            fancy_bg_img: bg_theme
          }
        });
      }
    }
  }, [currentPractice, login]);

  let is_admin = login && login.type === "User::Admin";
  let is_coach = login && login.type === "User::Coach";
  let is_client = login && login.type === "User::User";

  return (<ThemeProvider theme={theme}>
    <CssBaseline></CssBaseline>
    <Box sx={{
      display: "flex",
      // minHeight: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }}></Box>

    {(!!login || (!login && !!getCookie('nutriportal_token'))) && <SharedLayout version="0.0.1" loginId={login?.id} practice={currentPractice}>
      <Routes>
        {/* Deze routes bestaan om de refresh te laten renderen vooraleer login gevonden wordt */}
        {!is_client && !is_coach && <Route path='/inbox' element={<></>} />}
        {!is_client && !is_coach && <Route path='/news/:id' element={<></>} />}
        {!is_client && !is_coach && <Route path='/workshops' element={<></>} />}

        {!is_client && <Route path='/diary' element={<></>} />}
        {!is_client && <Route path='/diary_week' element={<></>} />}
        {!is_client && <Route path='/intake' element={<></>} />}
        {!is_client && <Route path='/wake' element={<></>} />}
        {!is_client && <Route path='/weight' element={<></>} />}
        {!is_client && <Route path='/find' element={<></>} />}
        {!is_client && <Route path='/practice' element={<></>} />}
        {!is_client && <Route path='/appointment' element={<></>} />}
        {!is_client && <Route path='/appointments' element={<></>} />}
        {!is_client && <Route path='/inbox/new' element={<></>} />}
        {!is_client && <Route path='/settings' element={<></>} />}
        {!is_client && <Route path='/news' element={<></>} />}

        {!is_coach && <Route path='/agenda' element={<></>} />}
        {!is_coach && <Route path='/exports' element={<></>} />}
        {!is_coach && <Route path='/export/:id' element={<></>} />}
        {!is_coach && <Route path='/users' element={<></>} />}
        {!is_coach && <Route path='/user/:id' element={<></>} />}
        {!is_coach && <Route path='/workshop/:id' element={<></>} />}
        {!is_coach && <Route path='/workshop/:id/subscribers' element={<></>} />}


        {is_client && <Route path='/' element={<ClientDashboard />} />}
        {is_client && <Route path='/diary' element={<ClientDiary />} />}
        {is_client && <Route path='/diary_week' element={<ClientDiaryWeek />} />}
        {is_client && <Route path='/intake' element={<ClientIntake />} />}
        {is_client && <Route path='/wake' element={<ClientMeasuresWake />} />}
        {is_client && <Route path='/weight' element={<ClientMeasuresWeight />} />}
        {is_client && <Route path='/find' element={<ClientPracticeFindPractice />} />}
        {is_client && <Route path='/practice' element={<ClientPracticeMyPractice />} />}
        {is_client && <Route path='/appointment' element={<ClientAppointmentNew />} />}
        {is_client && <Route path='/appointments' element={<ClientAppointmentList />} />}
        {is_client && <Route path='/workshops' element={<ClientAppointmentWorkshops />} />}
        {is_client && !is_coach && <Route path='/inbox' element={<NutriMail />} />}
        {is_client && <Route path='/inbox/new' element={<ClientEditNutriMail />} />}
        {is_client && <Route path='/settings' element={<ClientSettings />} />}
        {is_client && <Route path='/news' element={<ClientNews />} />}
        {is_client && !is_coach && <Route path='/news/:id' element={<SharedNewsItem />} />}


        {is_coach && <Route path='/' element={<CoachDashboard />} />}
        {is_coach && <Route path='/agenda' element={<CoachAgenda />} />}
        {is_coach && <Route path='/exports' element={<CoachExports />} />}
        {is_coach && <Route path='/export/:id' element={<CoachExportEdit />} />}
        {is_coach && <Route path='/users' element={<CoachUsers />} />}
        {is_coach && <Route path='/user/:id/detail' element={<CoachUserDetail />} />}
        {is_coach && <Route path='/user/:id' element={<CoachUserEdit />} />}
        {is_coach && <Route path='/workshops' element={<CoachWorkshops />} />}
        {is_coach && <Route path='/workshop/:id' element={<CoachWorkshopEdit />} />}
        {is_coach && <Route path='/workshop/:id/subscribers' element={<CoachWorkshopSubscribers />} />}


        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </SharedLayout>}
    
    {!login && !getCookie('nutriportal_token') && <Routes>
      {/* <Route path='/' element={<Landing/>} /> */}
      <Route path='/login' element={<SharedOnboarding />} />
      <Route path='/register' element={<SharedOnboarding isRegister={true} />} />
      <Route path='/confirm/:token' element={<SharedConfirmLoader />} />
      <Route path='/appointment' element={<ClientAppointmentNew />} />

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>}
  </ThemeProvider>);
}

